import React from 'react'
import Link from 'next/link'
import {EmblaOptionsType} from "embla-carousel";
import { useEmblaCarousel } from 'embla-carousel/react'
import urljoin from 'url-join'
import CarouselNext from './carousel_icon/next'
import CarouselPrev from './carousel_icon/prev'
import styles from '../styles/home_banner.module.scss'
import { useRegionLinkSubber } from '../hooks/region'
import {Banner} from "../types/models/banner";

const defaultCarouselSettings: EmblaOptionsType = {
  loop: true,
  align: 'center',
  containScroll: 'trimSnaps'
}

interface HomeBannerProps {
  banners: Banner[];
}

export default function HomeBanner({ banners }: HomeBannerProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(defaultCarouselSettings);
  const regionLinkSubber = useRegionLinkSubber();
  const autoplayIntervalRef = React.useRef<NodeJS.Timeout | null>();

  const shownBanners = React.useMemo(() => {
    const maxItems = 11 // Math.ceil(3840 / 470) + 2
    const newShownBanners = [...banners]
    while (newShownBanners.length <= maxItems) {
      newShownBanners.push(...banners)
    }

    return newShownBanners
  }, [banners])
  
  const handlePrevPage = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const handleNextPage = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  React.useEffect(() => {
    if (emblaApi && emblaApi.slideNodes().length !== shownBanners.length) {
      emblaApi.reInit()
    }
  }, [emblaApi, shownBanners])

  const clearAutoplayInterval = React.useCallback(() => {
    if (autoplayIntervalRef.current) {
      clearInterval(autoplayIntervalRef.current)
      autoplayIntervalRef.current = null
    }
  }, [autoplayIntervalRef])

  React.useEffect(() => {
    if (!emblaApi) return

    const startAutoplayInterval = () => {
      clearAutoplayInterval()
      autoplayIntervalRef.current = setInterval(() => {
        emblaApi.scrollNext()
      }, 5000)
    }

    startAutoplayInterval()

    const pointerDownCb = () => {
      clearAutoplayInterval()
    }
    const pointerUpCb = () => {
      startAutoplayInterval()
    }
    emblaApi.on('pointerDown', pointerDownCb)
    emblaApi.on('pointerUp', pointerUpCb)

    const visibilityChanged = () => {
      // console.log(`Tab state : ${document.visibilityState} ${document.hidden}`);
      if (document.hidden) {
        clearAutoplayInterval()
      } else {
        startAutoplayInterval()
      }
    }
    document.addEventListener('visibilitychange', visibilityChanged);

    return () => {
      clearAutoplayInterval()
      emblaApi.off('pointerDown', pointerDownCb)
      emblaApi.off('pointerUp', pointerUpCb)

      document.removeEventListener('visibilitychange', visibilityChanged);
    }
  }, [emblaApi])

  React.useEffect(() => {
    return () => {
      clearAutoplayInterval()
    }
  }, [])

  const handleBannerClick = React.useCallback((e) => {
    if (emblaApi && !emblaApi.clickAllowed()) {
      e.preventDefault()
    }
  }, [emblaApi])

  return <div className={styles.container}>
    <div className={styles.carousel} ref={emblaRef}>
      <div className={styles.carousel__container}>
        {shownBanners.map((banner, index) => {
          const { imagePath, link, name } = banner
          const imageUrl = urljoin(process.env.NEXT_PUBLIC_IMAGES_ENDPOINT!, imagePath)

          return <Link href={regionLinkSubber(link)} key={index}>
            <a className={styles.carousel__item} style={{ backgroundImage: `url('${imageUrl}')` }}  onClick={handleBannerClick}>
              <img className="img-fluid" src={imageUrl} alt={name} />
            </a>
          </Link>
        })}
      </div>
    </div>
    <button className={styles.prev_button} onClick={handlePrevPage}>
      <CarouselPrev />
    </button>
    <button className={styles.next_button} onClick={handleNextPage}>
      <CarouselNext />
    </button>
  </div>
}