import React from 'react'
import classNames from 'classnames'
import styles from '../styles/underline_nav.module.scss'

interface UnderlineNavProps {
  navItems: string[];
  activeIndex: number;
  onChange: (index: number) => void;
}

export default function UnderlineNav({navItems, activeIndex, onChange}: UnderlineNavProps) {
  const handleNavClick = React.useCallback((index) => () => onChange(index), [onChange]);
  const scrollRef = React.useRef<HTMLElement>(null);

  const onWheel = (e: WheelEvent) => {
    e.preventDefault()
    const container = scrollRef.current
    if (container) {
      const containerScrollPosition = container.scrollLeft

      container.scrollTo({
        top: 0,
        left: containerScrollPosition + e.deltaY  * 10
      })
    }
  };

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('wheel', onWheel, {
        passive: false,
      })
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.addEventListener('wheel', onWheel, {
          passive: false,
        })
      }
    }
  }, []);

  return <>
    <nav
      className={`${styles.productNav} mt-4`} 
      ref={scrollRef}
    >
      {navItems.map((label, index) => {
        const active = activeIndex === index
        const className = classNames(styles.productNav_item, {
          [styles.productNav_itemActive]: active,
        })
        return <div key={index} className={className}>
          <div className="d-inline-block">
            <button onClick={handleNavClick(index)}>
              {label}
            </button>
            <div className={active ? styles.productNav_activeHighlight : undefined}/>
          </div>
        </div>
      })}
    </nav >
    <div className={styles.productNav_line}/>
  </>
}

UnderlineNav.defaultProps = {
  initialActiveIndex: 0,
}