import React, { MouseEventHandler } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import productListingStyles from '../styles/product_listing.module.scss'
import { thumbUrl } from '../utils/image'
import SourceLabel from './source_label'
import { useRegionCountryCode, useRegionLinkPrefix } from '../hooks/region'
import MultilineEllipsis from './multiline_ellipsis'
import PriceLabel from "./price_label";
import { ProductListing, ProductWishListing } from "../types/models/product";
import * as gtag from '../lib/gtag';

interface ProductListingCardProps extends React.HTMLProps<HTMLAnchorElement> {
  product: ProductListing | ProductWishListing;
}

export default function ProductListingCard({ product, className, ...others }: ProductListingCardProps) {
  const regionInfo = useRegionCountryCode()
  const regionLinkPrefix = useRegionLinkPrefix()
  const anchorClassName = classNames(productListingStyles.productLink, className)

  const handleLinkClick: MouseEventHandler = React.useCallback(() => {
    gtag.selectItem(product, regionInfo.countryCode);
  }, [regionInfo.countryCode]);

  const shouldShowSoldCount = product.soldCount > 0;
  const productPriceClass = classNames(productListingStyles.productPrice, {
    'mb-0': shouldShowSoldCount
  });

  return <Link href={`${regionLinkPrefix}/product/${product.paramName}`}>
    <a className={anchorClassName} {...others} title={product.name} onClick={handleLinkClick}>
      <div className={`card mx-0 ${productListingStyles.productCard}`}>
        <div className={`card-img-top ${productListingStyles.productImageContainer}`}>
          <img src={thumbUrl(product.images[0])} className={productListingStyles.productImage} alt={product.name} />
        </div>

        <div className="card-body mt-5 mt-md-0">
          <div className={productListingStyles.productMarketplace}>
            <SourceLabel
              product={product}
            />
            {!product.conditionNew && " - USED"}
          </div>

          <div className={productListingStyles.productName}>
            <MultilineEllipsis
              text={product.name}
              lines={2}
            />
          </div>

          <p className={productPriceClass}>
            <PriceLabel product={product} regionInfo={regionInfo} />
          </p>
          {shouldShowSoldCount && (
            <div className={productListingStyles.productMarketplace + ' text-right'}>
              {product.soldCount} Sold
            </div>
          )}
        </div>
      </div>
    </a>
  </Link>
}
