import React from 'react'
import {useRouter} from 'next/router'
import Modal from 'react-bootstrap/Modal'
import classNames from 'classnames'
import Api from '../../service/api'
import HeadTitle from '../../components/head_title'
import LayoutFluid from '../../components/layout/layout_fluid'
import styles from '../../styles/home.module.scss'
import HomeProductGroupCategory from '../../components/home_product_group/category'
import SearchForm from '../../components/search_form'
import HomePopup from '../../components/home_popup'
import HomeBanner from '../../components/home_banner'
import {wrapper} from "../../store/store";
import * as staticProps from "../../lib/static_props"
import {handleCountryCodeCases} from "../../lib/static_props"
import {getRegionsCached} from "../../lib/region";
import {Banner} from "../../types/models/banner";
import {HomeProductGroup} from "../../types/models/home_product_group";
import HomeRecentlyViewedSection from "../../components/home/recently_viewed_section";

interface HomeProps {
  banners: Banner[];
}

function Home({ banners }: HomeProps) {
  const [showEmailVerifiedModal, setShowEmailVerifiedModal] = React.useState<boolean>(false)
  const [productGroups, setProductGroups] = React.useState<HomeProductGroup[]>([])
  const [stateBanners, setStateBanners] = React.useState<Banner[]>(banners);

  const router = useRouter()

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const showEmailVerified = !!router.query.email_verified
    setShowEmailVerifiedModal(showEmailVerified);
  }, [router.isReady, router.query]);

  React.useEffect(() => {
    Api.homeProductGroups.getAll()
      .then(res => {
        setProductGroups(res.data.groups)
      });
    Api.banner.getAll()
      .then(res => {
        setStateBanners(res.data.banners);
      });
  }, [])

  const bannerShown = banners.length > 0

  return <LayoutFluid>
    <HeadTitle title="Home">

      {/* {<!-- Primary Meta Tags -->} */}
      <meta name="title" content="Indo4ward | Shop 4ward from Indonesia ecommerce with Indo4ward"/>
      <meta name="description"
            content="Shop any ecommerce in Indonesia from anywhere in the world with Indo4ward. Simply provide us the Indonesian product link, make payments and we ship it straight to your house. Connect with Indonesia, and make your time outside of Indonesia a breeze."/>

      {/* {<!-- Open Graph / Facebook -->} */}
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.indo4ward.com/"/>
      <meta property="og:title" content="Indo4ward | Shop 4ward from Indonesia ecommerce with Indo4ward"/>
      <meta property="og:description"
            content="Shop any ecommerce in Indonesia from anywhere in the world with Indo4ward. Simply provide us the Indonesian product link, make payments and we ship it straight to your house. Connect with Indonesia, and make your time outside of Indonesia a breeze."/>
      <meta property="og:image"
            content="/images/home_og_image.png"/>

      {/* {<!-- Twitter -->} */}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://www.indo4ward.com/"/>
      <meta property="twitter:title" content="Indo4ward | Shop 4ward from Indonesia ecommerce with Indo4ward"/>
      <meta property="twitter:description"
            content="Shop any ecommerce in Indonesia from anywhere in the world with Indo4ward. Simply provide us the Indonesian product link, make payments and we ship it straight to your house. Connect with Indonesia, and make your time outside of Indonesia a breeze."/>
      <meta property="twitter:image"
            content="/images/home_og_image.png"/>

      {/* {<!-- react Helmet-->} */}
      <meta data-react-helmet="true"
            content="/images/home_og_image.png"
            name="image"/>
      <meta data-react-helmet="true" content="en" name="language"/>
      <meta data-react-helmet="true" content="https://www.indo4ward.com/" name="url"/>
      <meta data-react-helmet="true" content="index, follow" name="robots"/>
      <meta data-react-helmet="true"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            name="viewport"/>
      <meta charSet="UTF-8"/>

    </HeadTitle>

    {!showEmailVerifiedModal && (
      <HomePopup/>
    )}
    {/*<CnyHomeOverlay />*/}

    {bannerShown && (
      <div className={`pt-0 bg-primary-gradient ${styles.bannersSection}`}>
        <HomeBanner
          banners={stateBanners}
        />
      </div>
    )}

    <div className={classNames(
      `bg-light pb-5 position-relative`,
      styles.searchAndMarketplaceContainer,
      {
        [styles.bannerShown]: bannerShown
      }
    )}>
      <div className="container">
        <div className={classNames(styles.searchSection, {
          [styles.bannerShown]: bannerShown,
        })}>
          <div className={`d-flex flex-column shadow py-5 px-5 bg-white ${styles.searchSection_wrapper}`}>
            <h3 className="mb-4 text-center">Search/Paste your product link here:</h3>
            <SearchForm
              fieldClassName={`form-control ${styles.searchSection_formControl}`}
              buttonClassName={`btn btn-dark text-uppercase ${styles.searchSection_formButton}`}
              autoFocus={true}
            />
          </div>
        </div>

        <div className={styles.marketplaceSection}>
          <div className="font-weight-extrabold col-sm-auto px-sm-0 mb-2 mb-sm-0">
            Shop from any
            <br/>
            marketplace in Indonesia.
          </div>

          <div className="ml-sm-4 col-sm-auto px-sm-0 mb-2 mb-sm-0">
            <span className="font-weight-extrabold">Supported</span> 3<sup>rd</sup> Party
            <br/>
            Marketplaces:
          </div>

          <div className="ml-sm-4">
            <a href="https://www.tokopedia.com" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Tokopedia.svg"
                   alt="Tokopedia"/>
            </a>

            <a href="https://shopee.co.id" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Shopee.svg" alt="Shopee"/>
            </a>

            <a href="https://www.orami.co.id" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Orami.svg" alt="Orami"/>
            </a>

            <a href="https://www.blibli.com" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Blibli.svg" alt="Blibli"/>
            </a>

            <a href="https://www.jd.id" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Jdid.svg" alt="Jdid"/>
            </a>

            <a href="https://www.lazada.co.id" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Lazada.svg" alt="Lazada"/>
            </a>

            <a href="https://www.bukalapak.com" target="blank">
              <img className={styles.marketplace_icon} src="/images/supported_marketplace/Bukalapak.svg"
                   alt="Bukalapak"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <h4 className="text-center">Recently Viewed</h4>
    <div className="container mb-5 mt-5">
      <HomeRecentlyViewedSection/>
    </div>

    <div className={styles.productGroups}>
      {productGroups.map((productGroup, index) => {
        return <div className="container mb-3" key={index}>
          <HomeProductGroupCategory
            homeProductGroup={productGroup}
          />
        </div>
      })}
    </div>


    <div className={`bg-light py-5 ${styles.paymentMethodSection}`}>
      <div className="container text-center">
        <h5 className="pb-4">Secured Payment Methods</h5>

          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <img src="/images/merchants_partners/paynow.png" className="mx-4" alt="PayNow payment method" />
            <img src="/images/merchants_partners/MasterCard.png" className="mx-4" alt="Mastercard payment method" />
            <img src="/images/merchants_partners/Visa.png" className="mx-4" alt="Visa payment method" />
          </div>
      </div>
    </div>

    <Modal
      show={showEmailVerifiedModal}
      onHide={() => setShowEmailVerifiedModal(false)}
      size="lg"
      animation={false}
      className={styles.emailVerifiedModal}
    >
      <div className="row mx-0">
        <div className="col-auto bg-primary p-3 border-radius-left-1">
          <img src="/images/email-verified-illustration.jpg" alt="illustration"/>
        </div>
        <div className="col border-radius-right-1 p-3">
          <h3 className="text-primary">Hooray!</h3>
          You have successfully verified your account! Start your 4warding experience by pasting a product link on the search bar!

          <button className="btn btn-lg btn-primary font-weight-semibold text-uppercase"
                  onClick={() => setShowEmailVerifiedModal(false)}>
            Start Shopping
          </button>
        </div>
      </div>
    </Modal>
  </LayoutFluid>
}

export default Home

export async function getStaticPropsWithoutCountry() {
  let banners: Banner[] = [];
  try {
    const response = await Api.banner.getAll()
    banners = response.data.banners
  } catch (error) {
    console.error('failed to fetch banners@HomePage')
    console.error(error)
  }

  return {
    props: {
      banners,
    },
    revalidate: 300
  }
}

export const getStaticProps = wrapper.getStaticProps(store => handleCountryCodeCases(async function ({params}) {
  if (!params) {
    return {
      notFound: true
    };
  }
  await staticProps.populateServerSideStore(store.dispatch);

  const base = await getStaticPropsWithoutCountry();
  base.props = {
    ...base.props,
    ...staticProps.countryCode(params)
  };

  return base
}))

export async function getStaticPaths() {
  try {
    const regions = await getRegionsCached()

    const originalPaths = regions.map(r => ({
      params: {
        countryCode: r
      }
    }));

    return {
      paths: originalPaths,
      fallback: 'blocking'
    }
  } catch (error) {
    console.error('Failed to get region paths')
    console.error(error)

    return {
      paths: [],
      fallback: 'blocking'
    }
  }
}
