import React from 'react'
import Modal from 'react-overlays/Modal'
import {AiFillCloseCircle} from 'react-icons/ai'
import urljoin from 'url-join'
import Link from 'next/link'
import {ModalProps} from "react-bootstrap";
import Api from '../service/api'
import {useRegionLinkSubber} from '../hooks/region'
import {isUrlAbsolute} from '../utils/url'
import {Popup} from "../types/models/popup";
import {epochTime} from "../utils/date";
import * as gtag from "../lib/gtag";
import {useRouter} from "next/router";
import moment from "moment";

interface PopupData {
  popups: Popup[],
  lastIdx: number,
  updatedAt: number
}

const Backdrop = function (props: React.HTMLProps<HTMLDivElement>) {
  const style: React.CSSProperties = {
    "position": "fixed",
    "zIndex": 1040,
    "top": "0",
    "bottom": "0",
    "left": "0",
    "right": "0",
    "backgroundColor": "#000",
    "opacity": "0.5"
  };
  return <div {...props} style={style}/>;
}

// we use some pseudo random coords so nested modals
// don't sit right on top of each other.
const RandomlyPositionedModal = function (props: ModalProps) {
  const style: React.CSSProperties = {
    "position": "fixed",
    "zIndex": 1040,
    "top": 0,
    "bottom": 0,
    "left": 0,
    "right": 0,
  };
  return <Modal {...props} style={style}/>;
}

const persistPopupData = (newPopupData: PopupData | null) => {
  if (newPopupData === null) {
    window.localStorage.removeItem('popupData');
  } else {
    window.localStorage.setItem('popupData', JSON.stringify(newPopupData));
  }
}

const incrementIndexPopupData = (popupData: PopupData) => {
  const newPopupData = {
    popups: popupData.popups,
    lastIdx: popupData.lastIdx <= popupData.popups.length - 1 ? popupData.lastIdx + 1 : popupData.lastIdx,
    updatedAt: epochTime()
  }
  persistPopupData(newPopupData);
  return newPopupData;
}
const retrievePopupData = () => {
  try {
    const jsonStr = window.localStorage.getItem('popupData');
    if (!jsonStr) {
      return null;
    }

    return JSON.parse(jsonStr) as PopupData;
  } catch (e) {
    return null
  }
}

const loadPopupData = async () => {
  let popupData = await retrievePopupData();
  const response = await Api.popup.get();
  const {data} = response;
  const {popups} = data;

  if (!popupData || data.lastUpdatedAt > popupData.updatedAt ||
    moment().isSameOrAfter(moment.unix(popupData.updatedAt).add(moment.duration(3, 'hours')))) {
    let newPopupData: PopupData = {
      popups,
      lastIdx: 0,
      updatedAt: epochTime()
    };
    persistPopupData(newPopupData);
    popupData = newPopupData;
  }

  return popupData;
}

export default function HomePopup() {
  const [show, setShow] = React.useState(true);
  const [popup, setPopup] = React.useState<Popup | null>(null);
  const router = useRouter()
  const regionLinkSubber = useRegionLinkSubber();

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const {query} = router
    const routerCountryCode = Array.isArray(query.countryCode) ? query.countryCode[0] : query.countryCode;

    if (!routerCountryCode) {
      return;
    }

    loadPopupData().then((popupData) => {
      if (popupData.lastIdx < popupData.popups.length) {
        setPopup(popupData.popups[popupData.lastIdx]);
        gtag.popupShown(popupData.popups[popupData.lastIdx]);
        incrementIndexPopupData(popupData);
      }
    });
  }, [router.query.countryCode, router.isReady]);

  const handleHide = React.useCallback((e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setShow(false);
  }, []);

  const handlePopupClick = React.useCallback((popup: Popup) => () => gtag.popupClicked(popup), []);

  const modalVisible = show && !!popup;
  return (
    <RandomlyPositionedModal
      show={modalVisible}
      onHide={handleHide}
      renderBackdrop={Backdrop}
      aria-labelledby="modal-label"
    >
      <div
        onClick={handleHide}
        className="d-flex flex-row align-items-center justify-content-center h-100"
      >
        {!!popup && (
          <div className="position-relative">
            <button
              style={{
                position: 'absolute',
                right: '0',
                top: '0',
                backgroundColor: 'transparent',
                color: 'white',
                border: '0',
                zIndex: 5,
              }}
              onClick={handleHide}
            >
              <AiFillCloseCircle size="2em"/>
            </button>

            {isUrlAbsolute(popup.link) ? (
              <a href={regionLinkSubber(popup.link)} onClick={handlePopupClick(popup)}>
                <img
                  src={urljoin(process.env.NEXT_PUBLIC_IMAGES_ENDPOINT!, popup.imagePath)}
                  alt="popup"
                  className="img-fluid"
                />
              </a>
            ) : (
              <Link href={regionLinkSubber(popup.link)}>
                <a onClick={() => handlePopupClick(popup)}>
                  <img
                    src={urljoin(process.env.NEXT_PUBLIC_IMAGES_ENDPOINT!, popup.imagePath)}
                    alt="popup"
                    className="img-fluid"
                  />
                </a>
              </Link>
            )}

          </div>
        )}
      </div>
    </RandomlyPositionedModal>
  );
}