import React from 'react';
import {EmblaOptionsType} from "embla-carousel";
import { useEmblaCarousel } from 'embla-carousel/react';

import ProductListingCard from './product_listing_card';
import styles from '../styles/horizontal_scrolling_products.module.scss';
import CarouselNext from './carousel_icon/next';
import CarouselPrev from './carousel_icon/prev';
import useWindowSize from '../hooks/use_window_size';
import {ProductListing} from "../types/models/product";

const defaultCarouselSettings: EmblaOptionsType = {
  containScroll: 'trimSnaps'
};

interface HorizontalScrollingProductsProps {
  products: ProductListing[];
}

export default function HorizontalScrollingProducts({ products }: HorizontalScrollingProductsProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(defaultCarouselSettings);
  
  const windowSize = useWindowSize();

  const handlePrevPage = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const handleNextPage = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  React.useEffect(() => {
    if (!emblaApi) return
    
    const responsiveVisibleItemCounts = [
      [992, 5],
      [768, 4],
      [576, 3],
      [0, 2]
    ]
    const windowWidth = windowSize.width
    if (!windowWidth) return;

    for (const responsiveVisibleItemCount of responsiveVisibleItemCounts) {
      const [minWidth, visibleItemCount] = responsiveVisibleItemCount
      if (windowWidth >= minWidth) {
        emblaApi.reInit({
          ...defaultCarouselSettings,
          slidesToScroll: visibleItemCount
        })
        return
      }
    }
  }, [windowSize.width, emblaApi])

  React.useEffect(() => {
    if (emblaApi && emblaApi.slideNodes().length !== products.length) {
      emblaApi.reInit()
    }
  }, [emblaApi, products])

  const handleItemClick = React.useCallback((e) => {
    if (emblaApi && !emblaApi.clickAllowed()) {
      e.preventDefault()
    }
  }, [emblaApi])

  return <div className={styles.container}>
    <div className={styles.carousel} ref={emblaRef}>
      <div className={styles.carousel__container}>
        {products.map((product, index) => {
          return <ProductListingCard 
            key={index} 
            product={product} 
            className={styles.carousel__item}
            onClick={handleItemClick}
          />
        })}
      </div>
    </div>
    <button className={styles.prev_button} onClick={handlePrevPage}>
      <CarouselPrev />
    </button>
    <button className={styles.next_button} onClick={handleNextPage}>
      <CarouselNext />
    </button>
  </div>
}
